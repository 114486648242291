.unauthorized-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f0f0f0;
}

    .unauthorized-container h1 {
        margin-bottom: 20px;
        font-size: 2rem;
        color: #333;
    }

    .unauthorized-container p {
        font-size: 1.2rem;
        color: #666;
    }
