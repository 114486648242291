.dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: black;
    color: white;
    font-family: 'Gilroy', sans-serif;
}

.dashboardHeader {
    background-color: #1A1A1D;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .dashboardHeader h1 {
        margin: 0;
    }

.headerRight {
    display: flex;
    align-items: center;
    gap: 16px;
}

    .headerRight p {
        margin: 0;
    }

.logoutButton {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.3s;
}

    .logoutButton:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

.dashboardContent {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.categoryNav {
    width: 200px;
    background-color: #242426;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
}

.categoryGroup h3 {
    margin: 0 0 8px 0;
    font-size: 14px;
    color: #999;
    text-transform: uppercase;
}

.categoryNav button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background-color 0.3s;
    text-align: left;
    width: 100%;
}

    .categoryNav button.active,
    .categoryNav button:hover {
        background-color: #2A2A2C;
    }

.mySessionsBtn {
    background-color: #1071FF !important;
    color: white !important;
}

    .mySessionsBtn:hover,
    .mySessionsBtn.active {
        background-color: #0056b3 !important;
    }

.dashboardMain {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.notification {
    position: absolute;
    top: 16px;
    left: 16px;
    right: 16px;
    padding: 10px;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
    z-index: 10;
}

    .notification.success {
        background-color: rgba(76, 175, 80, 0.1);
        color: #4CAF50;
    }

    .notification.error {
        background-color: rgba(255, 68, 68, 0.1);
        color: #ff4444;
    }

.componentWrapper {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
}
