.context-menu {
    position: fixed;
    padding: 8px;
    background: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: scale(0.95);
}

    .context-menu.show {
        opacity: 1;
        pointer-events: auto;
        transform: scale(1);
    }

.context-menu-option {
    padding: 8px;
    cursor: pointer;
    color: #050037;
    font-size: 14px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    line-height: 18px;
    width: 100%;
    box-sizing: border-box;
}

    .context-menu-option:hover {
        background: rgba(0, 0, 0, 0.08);
        border-radius: 8px;
    }
