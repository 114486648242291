.program-creator {
    width: 100%;
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    background-color: #000000;
}

.node-buttons {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    flex-shrink: 0; /* Предотвращаем сжатие кнопок */
}

.node-buttons button {
    margin: 0 5px;
    padding: 8px 16px;
    background-color: #1071FF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.node-buttons button:hover {
    background-color: #0056b3;
}

.react-flow__node {
    font-size: 12px;
    background: transparent;
    border: none;
    border-radius: 5px;
    text-align: center;
}