.tabbar-container {
    display: flex;
    padding: var(--12-px, 12px) var(--16-px, 16px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.tabbar-left {
    flex: 1 1 0;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.tabbar-right {
    flex: 1 1 0;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    gap: 12px;
}

.tabbar-center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.recording-mode {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    text-transform: capitalize;
}

.mic-container {
    display: flex;
    padding-right: 4px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 48px;
    background: rgba(255, 255, 255, 0.16);
    cursor: pointer;
}

.mic {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 80px;
}

.mic-mic {
    display: flex;
    width: 48px;
    height: 48px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    background: rgba(255, 255, 255, 0.2);
}

    .mic-mic.active.listening {
        background: var(--Primary-Earth-Light, linear-gradient(0deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.72) 100%), #1071FF);
    }

    .mic-mic.active.hold {
        background: var(--Primary-Earth-Light, linear-gradient(0deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.72) 100%), #10FF86);
    }

.tabbar-item-icon {
    width: 24px;
    height: 24px;
}

.leave-container {
    padding: 8px;
    background: #FF6272;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings-container {
    padding: 8px;
    background: rgba(255, 255, 255, 0.24);
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.language-toggle {
    background: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .language-toggle:hover {
        background: rgba(255, 255, 255, 0.3);
    }

.volume-indicator-container {
    width: 50px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    overflow: hidden;
}

.volume-indicator {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    transition: width 0.1s ease-out;
}