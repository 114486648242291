.programLevels {
    margin-top: 16px;
}

.levelItem {
    background-color: #1A1A1D;
    border: 1px solid #333;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 12px;
}

    .levelItem h4 {
        margin-top: 0;
        margin-bottom: 8px;
        color: #1071FF;
    }

    .levelItem p {
        margin: 4px 0;
        font-size: 0.9em;
        color: #999;
    }

.removeButton {
    background-color: #ff4444;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    margin-top: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .removeButton:hover {
        background-color: #cc0000;
    }
