.commonLayout {
    display: flex;
    height: 100%;
    overflow: hidden;
}

.leftPanel, .rightPanel {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.leftPanel {
    max-width: 300px;
    border-right: 1px solid #333;
}

.panelTitle {
    margin-top: 0;
    margin-bottom: 16px;
}

.panelContent {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
