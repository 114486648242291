.content-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.content-inner {
    flex: 1;
    align-self: stretch;
    background: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content-header {
    align-self: stretch;
    padding: 10px 16px;
    background: white;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    height: 44px;
    z-index: 2; /* Градиент выше сообщений */
}

    .content-header h2 {
        margin: 0;
        font-weight: 600;
        color: #050037;
        font-size: 20px;
        font-family: 'Gilroy', sans-serif;
    }

.close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 80px;
}
    .close-button:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

.content-body {
    flex: 1;
    overflow-y: auto;
}