@font-face {
    font-family: 'Gilroy';
    src: url('./Gilroy-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('./Gilroy-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
