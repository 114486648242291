.brainstorm-container {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    background: #FFFFFF;
    height: calc(100% - 64px); /* Изменено как указано */
    position: relative;
}

.brainstorm-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 80px;
}

.brainstorm-header {
}

.brainstorm-title {
    font-family: 'Gilroy', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 0px;
    color: #050037;
}

.brainstorm-description {
    display: flex;
    gap: 16px;
    margin-bottom: 8px;
}

.brainstorm-image {
    width: 270px;
    height: 151.88px;
    background: #3D3124;
    border: 1px solid rgba(5, 0, 55, 0.08);
    border-radius: 8px;
}

.brainstorm-text {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #050037;
}

.brainstorm-ideas {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
}

.idea-item {
    padding: 8px 12px 8px 12px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.72)), #1071FF;
    border-radius: 8px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #050037;
}

.brainstorm-form {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    position: absolute;
    bottom: 32px;
    left: 24px;
    right: 24px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%);
    padding-top: 40px;
    z-index: 1;
}

.input-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.input-label {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #1071FF;
    transform: translateY(8px); /* Добавлено как указано */
}

.idea-input {
    width: 100%;
    padding: 8px 0;
    border: none;
    border-bottom: 2px solid #1071FF;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #050037;
    outline: none;
}

.submit-button {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 16px 8px 14px;
    background: rgba(16, 113, 255, 0.08);
    border-radius: 80px;
    border: none;
    cursor: pointer;
    font-family: 'Gilroy', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1071FF;
}

    .submit-button:hover {
        background: rgba(16, 113, 255, 0.16);
    }

/* Добавленные стили для скроллбара */
.brainstorm-content::-webkit-scrollbar {
    width: 4px;
}

.brainstorm-content::-webkit-scrollbar-track {
    background: transparent;
}

.brainstorm-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

    .brainstorm-content::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }

.brainstorm-svg {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 200px; /* Add a minimum height to ensure visibility */
}