.base-node {
    background-color: #2A2A2D;
    border: 2px solid #3A3A3D;
    border-radius: 8px;
    color: white;
    font-size: 12px;
    width: 240px;
    overflow: hidden;
}

.node-header {
    background-color: #3A3A3D;
    font-weight: bold;
    font-size: 14px;
    padding: 8px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-node {
    background: none;
    border: none;
    color: #ff4444;
    font-size: 18px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    line-height: 1;
}

    .delete-node:hover {
        color: #ff0000;
    }

.node-content {
    padding: 10px;
}

.node-io {
    display: flex;
    align-items: center;
    margin: 5px 0;
    height: 30px;
    position: relative;
}

.input .io-label {
    padding-left: 20px;
}

.output .io-label {
    padding-right: 20px;
}

.io-label {
    flex-grow: 1;
    padding: 5px 10px;
    background-color: #1A1A1D;
    border-radius: 4px;
    text-align: center;
}

.node-field {
    margin-bottom: 10px;
}

    .node-field label {
        display: block;
        margin-bottom: 5px;
        color: #888;
    }

    .node-field input,
    .node-field select {
        width: 100%;
        padding: 5px;
        background-color: #1A1A1D;
        border: 1px solid #333;
        color: white;
        border-radius: 4px;
        box-sizing: border-box;
    }

.node-button {
    width: 100%;
    padding: 8px;
    background-color: #1071FF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

    .node-button:hover {
        background-color: #0056b3;
    }

.react-flow__handle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #1071FF;
}

.react-flow__handle-left {
    left: -5px;
}

.react-flow__handle-right {
    right: -5px;
}

/* Remove white edges */
.react-flow__node {
    border: none !important;
    box-shadow: none !important;
}
