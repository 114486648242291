.settings-window {
    position: fixed;
    min-width: 250px;
    min-height: 100px;
    background: #050037;
    border-radius: 8px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    z-index: 9999;
    overflow: hidden;
    resize: both;
}

.settings-window-header {
    padding: 10px 16px;
    background: #050037;
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
    user-select: none;
    height: 40px;
}

.settings-window-title {
    color: white;
    font-size: 16px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 700;
    line-height: 20px;
}

.settings-window-close {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    transition: background-color 0.2s ease;
}

    .settings-window-close:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

.settings-window-content {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    color: white;
    font-family: 'Gilroy', sans-serif;
}

    .settings-window-content h3 {
        font-size: 16px;
        font-weight: 700;
        margin-top: 16px;
        margin-bottom: 8px;
    }

    .settings-window-content p {
        font-size: 14px;
        margin-bottom: 4px;
    }

.settings-window-resize-handle {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    cursor: se-resize;
}

/* Scrollbar styles */
.settings-window-content::-webkit-scrollbar {
    width: 4px;
}

.settings-window-content::-webkit-scrollbar-track {
    background: transparent;
}

.settings-window-content::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
}

    .settings-window-content::-webkit-scrollbar-thumb:hover {
        background-color: rgba(255, 255, 255, 0.3);
    }
