.bot-message-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 8px;
    width: 347px;
    height: 56px;
    background: rgba(16, 113, 255, 0.08);
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    margin: 8px 0;
    align-self: flex-start;
}

    .bot-message-button:hover {
        background: rgba(16, 113, 255, 0.12);
    }

.icon-brain {
    width: 32px;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.button-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
    width: 243px;
    height: 30px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.button-title {
    width: 100%;
    height: 14px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #050037;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button-subtitle {
    width: 100%;
    height: 14px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #050037;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.icon-arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px;
    width: 24px; /* �������� ������ */
    height: 24px; /* �������� ������ */
    background: rgba(16, 113, 255, 0.08);
    border-radius: 80px;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin-left: auto; /* �������� ������ ����� */
}

    .icon-arrow img {
        width: 20px;
        height: 20px;
    }

/* �������� ��� � ��� ���� BodyTS.css */
.chatbox-message.bot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
