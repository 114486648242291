.chatbox-container {
    position: relative; /* Для позиционирования вебкамеры */
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.chatbox-inner {
    flex: 1 1 0;
    align-self: stretch;
    background: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%; /* Ensure full height */
}

.chatbox-header {
    align-self: stretch;
    padding: 10px 16px;
    background: white;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    height: 44px;
    z-index: 2; /* Градиент выше сообщений */
}

.chatbox-header-title {
    flex: 1 1 0;
    height: 44px;
    color: #050037;
    font-size: 20px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.chatbox-header-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.chatbox-header-icon {
    padding: 8px;
    background: rgba(255, 255, 255, 0);
    border-radius: 80px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
}

.chatbox-header-icon-img {
    width: 24px;
    height: 24px;
}

/* Добавляем градиентный оверлей в CSS */
.chatbox-gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px; /* Высота градиента */
    background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
    pointer-events: none; /* Градиент не должен блокировать клики */
    z-index: 1; /* Градиент выше сообщений */
}

.chatbox-messages-container {
    position: relative;
    flex: 1;
    width: 100%;
    overflow: hidden; /* Ensure the gradient overlay doesn't scroll */
}

.chatbox-messages {
    align-self: stretch;
    flex: 1 1 0;
    padding: 16px;
    display: flex;
    flex-direction:column-reverse; /* Это реверсирует порядок сообщений */
    align-items: flex-start;
    gap: 16px;
    overflow-y: auto;
    max-height: calc(100% - 38px); /* Вычитает высоту заголовка и футера */
    position: relative; /* Для позиционирования градиентного оверлея */
    height: calc(100% - 38px); /* Убедитесь, что высота уменьшена на высоту заголовка и футера */
}

.chatbox-message {
    max-width: 70%;
    padding: 12px 16px;
    border-radius: 20px;
    display: inline-flex;
    justify-content: flex-start;
    gap: 8px;
    word-wrap: break-word;
    z-index: 0; /* Ensure messages are below the gradient */
}

    .chatbox-message.user {
        background: rgba(0, 0, 0, 0.08);
        border-radius: 20px 20px 4px 20px;
        justify-content: flex-end;
        align-self: flex-end;
    }

    .chatbox-message.bot {
        max-width: 100%;
        width: fit-content;
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 20px 20px 20px 4px;
        align-self: flex-start;
    }

    .chatbox-message.instruct {
        background: linear-gradient(270deg, #a0ffaf, #5cbf60, #a0ffaf);
        background-size: 600% 600%;
        border-radius: 20px 20px 4px 20px;
        animation: gradient-move 6s ease infinite;
        color: white !important;
        font-weight: bold;
        justify-content: flex-end;
        align-self: flex-end;
    }

        .chatbox-message.instruct .chatbox-message-content {
            color: white !important;
            font-weight: bold;
        }

.chatbox-system-message {
    max-width: 100%;
    display: inline-flex;
    justify-content: center;
    word-wrap: break-word;
    z-index: 0; /* Ensure messages are below the gradient */
}

    .chatbox-system-message.system {
        width: 100%;
        text-align: center;
        color: #6B7280;
        font-size: 12px;
        font-family: 'Gilroy', sans-serif;
        font-weight: 500;
        line-height: 18px;
        background: none;
        border-bottom: 1px solid #E5E7EB;
    }

@keyframes gradient-move {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.chatbox-message-content {
    color: #050037;
    font-size: 14px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    line-height: 18px;
    word-wrap: break-word;
}

.chatbox-footer {
    align-self: stretch;
    padding: 8px 16px;
    background: white;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.chatbox-assistent {
    align-self: stretch;
    padding: 8px 16px;
    background: white;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.chatbox-footer-input {
    flex: 1 1 0;
    height: 18px;
    color: rgba(5, 0, 55, 0.50);
    font-size: 14px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    line-height: 18px;
    border: none;
    outline: none;
}

.chatbox-footer-send {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.chatbox-footer-send-icon {
    width: 24px;
    height: 24px;
}

.chatbox-footer-send-text {
    color: #1071FF;
    font-size: 16px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
}

.typing-indicator {
    width: 100%;
    padding-top: 1px;
    padding-bottom: 1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.typing-indicator.active {
    opacity: 1;
}

.typing-dots {
    display: flex;
    height: 5px;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.dot {
    width: 5px;
    height: 5px;
    background: rgba(0, 0, 0, 0.24);
    border-radius: 9999px;
    animation: blink 1.4s infinite both;
}

.dot:nth-child(2) {
    background: rgba(5, 0, 55, 0.16);
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    background: rgba(5, 0, 55, 0.08);
    animation-delay: 0.4s;
}

.typing-text {
    color: rgba(5, 0, 55, 0.70);
    font-size: 12px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    line-height: 14px;
    word-wrap: break-word;
}

@keyframes blink {
    0%, 80%, 100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}

/* Scrollbar styles */
.chatbox-messages::-webkit-scrollbar {
    width: 4px;
}

.chatbox-messages::-webkit-scrollbar-track {
    background: transparent;
}

.chatbox-messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.chatbox-messages::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.chatbox-message-content {
    display: inline;
}

.bot-message-button {
    align-self: flex-start;
    width: auto;
    max-width: 100%;
}

.button-subcontainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0; /* Увеличенные отступы сверху и снизу */
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

    .button-subcontainer.visible {
        opacity: 1;
        transform: translateY(0);
    }
