.editForm {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

    .editForm h3 {
        margin-top: 0;
        margin-bottom: 16px;
    }

.formFields {
    flex: 1;
    overflow-y: auto;
    padding-right: 16px; /* Добавляем отступ справа для скроллбара */
}

    /* Стили для скроллбара */
    .formFields::-webkit-scrollbar {
        width: 8px;
    }

    .formFields::-webkit-scrollbar-track {
        background: #1A1A1D;
    }

    .formFields::-webkit-scrollbar-thumb {
        background-color: #4A4A4D;
        border-radius: 4px;
    }

        .formFields::-webkit-scrollbar-thumb:hover {
            background-color: #5A5A5D;
        }

.formField {
    margin-bottom: 16px;
}

    .formField label {
        display: block;
        margin-bottom: 8px;
    }

    .formField input,
    .formField textarea,
    .formField select {
        width: 100%;
        padding: 8px;
        background-color: #1A1A1D;
        border: 1px solid #333;
        color: white;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .formField select {
        appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 50%;
    }

.formButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    gap: 8px;
}

    .formButtons button {
        padding: 8px 16px;
        background-color: #1071FF;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
    }

        .formButtons button:hover {
            background-color: #0056b3;
        }

        .formButtons button:nth-child(2) {
            background-color: #ff4444;
        }

            .formButtons button:nth-child(2):hover {
                background-color: #cc0000;
            }
