.commonListWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    background-color: #242426; /* Восстанавливаем серый фон */
    border-radius: 8px;
    padding: 16px;
}

.commonList {
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
    margin-bottom: 16px;
}

    /* Стили для скроллбара остаются без изменений */
    .commonList::-webkit-scrollbar {
        width: 8px;
    }

    .commonList::-webkit-scrollbar-track {
        background: #1A1A1D;
    }

    .commonList::-webkit-scrollbar-thumb {
        background-color: #4A4A4D;
        border-radius: 4px;
    }

        .commonList::-webkit-scrollbar-thumb:hover {
            background-color: #5A5A5D;
        }

.createNewButton {
    flex-shrink: 0;
    margin-top: 16px;
}
