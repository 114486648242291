.header-container {
    width: 97.7%;
    height: 40px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.header-inner {
    flex: 1 1 0;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.header-box {
    padding: 8px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.header-icon-container {
    width: 24px;
    height: 24px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-icon {
    width: 100%;
    height: 100%;
    background: url('../../../assets/images/TS/icon-online-training.svg') no-repeat center center;
    background-size: contain;
}

.header-text-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.header-title {
    color: white;
    font-size: 16px;
    font-family: 'Gilroy';
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
}

.header-dot {
    color: rgba(255, 255, 255, 0.50);
    font-size: 16px;
    font-family: 'Gilroy';
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
}

.header-time {
    display: inline-block;
    color: rgba(255, 255, 255, 0.50);
    font-size: 16px;
    font-family: 'Gilroy';
    font-weight: 500;
    line-height: 20px;
    word-wrap: break-word;
    width: 70px; /* фиксированная ширина для времени */
    text-align: center; /* центрирование текста */
}

.header-arrow-container {
    width: 20px;
    height: 20px;
    position: relative;
}

.header-arrow {
    width: 100%;
    height: 100%;
    background: url('../../../assets/images/TS/icon-chevron-down.svg') no-repeat center center;
    background-size: contain;
}

.header-gradient {
    padding: 1.8px;
    background: linear-gradient(0deg, #1071FF 0%, #1071FF 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.72) 100%);
    border-radius: 48px;
}

.header-buttons {
    display: flex;
    gap: 8px;
    align-items: center;
}

.header-button {
    padding: 8px 16px;
    background-color: #1071FF;
    color: white;
    border: none;
    border-radius: 4px;
    font-family: 'Gilroy';
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

    .header-button:hover {
        background-color: #0056b3;
    }

.exit-button {
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    padding: 0;
}

    .exit-button:hover {
        background-color: #FF6272;
    }

    .exit-button svg {
        width: 20px;
        height: 20px;
    }