.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
    position: relative;
    overflow: hidden;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 200px;
    padding: 20px;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    position: absolute;
}

.hidden {
    opacity: 0;
    transform: translateY(10px);
    pointer-events: none; /* Чтобы скрытый элемент не блокировал клики */
}

.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
}

.animating {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.login-container h1 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #050037;
    font-family: 'Gilroy', sans-serif;
    font-weight: 700;
    line-height: 24px;
}

.login-container input {
    margin: 10px 0;
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    outline: none;
    font-family: 'Gilroy', sans-serif;
    box-sizing: border-box;
}

.login-container button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #1071FF;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Gilroy', sans-serif;
    margin-top: 20px;
}

    .login-container button:hover {
        background-color: #0a58ca;
    }

.login-container .error {
    margin-top: 20px;
    color: red;
    font-size: 1rem;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    line-height: 18px;
}

.login-container .toggle-link {
    position: absolute;
    bottom: 20px;
    font-size: 1rem;
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    color: #1071FF;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.3s ease;
}

    .login-container .toggle-link:hover {
        color: #0a58ca;
    }


.users-list {
    margin-top: 20px;
    text-align: left;
    width: 100%;
}

    .users-list ul {
        list-style-type: none;
        padding: 0;
    }

    .users-list li {
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
    }

    .users-list h2 {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
