.listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #444;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .listItem:hover {
        background-color: #2A2A2C;
    }

.itemInfo {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
}

.itemTitle {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.itemDescription {
    font-size: 0.9em;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.deleteButton {
    background: none;
    border: none;
    color: #ff4444;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
    margin-left: 10px;
    opacity: 0;
    transition: opacity 0.3s;
}

.listItem:hover .deleteButton {
    opacity: 1;
}

.deleteButton:hover {
    color: #ff6666;
}
