.mySessionsComponent {
}

    .mySessionsComponent h2 {
        margin-bottom: 16px;
        margin-top: 0px;
    }

.sessionsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
}

.errorMessage, .successMessage {
    padding: 8px;
    border-radius: 4px;
    margin-top: 16px;
}

.errorMessage {
    color: #ff4444;
    background-color: rgba(255, 68, 68, 0.1);
}

.successMessage {
    color: #4CAF50;
    background-color: rgba(76, 175, 80, 0.1);
}
