.sessionLevelControl {
    background-color: #242426;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
}

    .sessionLevelControl h4 {
        margin-top: 0;
        margin-bottom: 12px;
        color: #1071FF;
    }

    .sessionLevelControl p {
        margin: 4px 0;
        font-size: 0.9em;
        color: #999;
    }

.levelButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.incrementButton,
.resetButton {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.incrementButton {
    background-color: #4CAF50;
    color: white;
}

    .incrementButton:hover {
        background-color: #45a049;
    }

.resetButton {
    background-color: #ff4444;
    color: white;
}

    .resetButton:hover {
        background-color: #cc0000;
    }
