.App {
    text-align: center;
}

.container {
    height: 90vh;
    width: 90vw;
	max-width: 1280px;
    margin: auto;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.1%, -50.1%);
    border-radius: 20px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
