/* Стиль для летающего блока "вебкамеры" */
.webcam {
    display: flex;
    width: 180px;
    height: 180px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 3px 8px 0px rgba(0, 0, 0, 0.20);
    position: absolute; /* Absolute positioning for movement */
    background: white;
    cursor: grab;
    z-index: 10; /* Градиент выше сообщений */
}

    .webcam.dragging {
        cursor: grabbing;
    }

    /* Блокировка выделения текста */
    .no-select {
        user-select: none;
    }

.headphone-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
    width: 24px; /* Добавлено для определения размера контейнера */
    height: 24px; /* Добавлено для определения размера контейнера */
}

/* Новый стиль для линии зачеркивания */
.strikethrough {
    position: absolute;
    width: 34px; /* Чуть шире, чем иконка */
    height: 2px; /* Толщина линии */
    background-color: gray;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
}