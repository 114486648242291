.containerMainTS {
    background-color: #242426;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
}

.containerTS {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: stretch;
    background-color: black;
    overflow: hidden;
}

.BodyTS {
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-height: 0;
    overflow: hidden;
}

.BodyTS-content {
    display: flex;
    width: 1248px;
    max-width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    position: relative;
}

.BodyTS-main {
    width: 624px;
    height: 100%;
    transition: all 0.5s ease;
    flex-shrink: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.BodyTS-content.double .BodyTS-main {
    width: 420px;
    min-width: 420px;
    left: 0;
    transform: translateX(0);
}

.BodyTS-secondary {
    width: calc(65% - 8px);
    height: 100%;
    transition: all 0.5s ease;
    flex-shrink: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: -65%;
}

.BodyTS-content.double .BodyTS-secondary {
    opacity: 1;
    right: 0;
    width: calc(100% - 436px);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.BodyTS-content.double .BodyTS-secondary {
    animation: fadeIn 0.5s ease;
}

.BodyTS-inner, .BodySecondTS-inner {
    height: 100%;
    overflow-y: auto;
    padding: 16px 0;
}

@media (max-width: 800px) {
    .BodyTS-content {
        width: 100%;
    }

        .BodyTS-main, .BodyTS-content.double .BodyTS-main {
            width: 100%;
            left: 0;
            right: 0;
            transform: none;
        }

        .BodyTS-secondary, .BodyTS-content.double .BodyTS-secondary {
            width: 100%;
            left: 0;
            right: 0;
            transform: none;
        }

        .BodyTS-content.double .BodyTS-secondary {
            display: block;
        }
}
