.sessionCard {
    background-color: #242426;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

    .sessionCard h3 {
        margin: 0;
        margin-bottom: 8px;
    }

.sessionId {
    font-size: 0.9em;
    color: #999;
    margin-bottom: 16px;
}

.sessionActions {
    display: flex;
    justify-content: flex-end;
    align-self: end;
    margin-top: auto;
}

.enterButton, .deleteButton {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.enterButton {
    background-color: #1071FF;
    color: white;
}

    .enterButton:hover {
        background-color: #0056b3;
    }

.deleteButton {
    background-color: transparent;
    color: #ff4444;
}

    .deleteButton:hover {
        background-color: rgba(255, 68, 68, 0.1);
    }
