.createNewButton {
    width: 100%;
    padding: 10px;
    margin-top: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

    .createNewButton:hover {
        background-color: #45a049;
    }
